<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button v-if="disabled" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="!disabled" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>

    <h2 class="card-title">Customer information</h2>
    <div class="card related-user-card">
      <el-form class="related-user-form" ref="related-user-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Related Customer">
            <el-autocomplete
            class="inline-input addMemberInput"
            :fetch-suggestions="searchCustomer"
            v-model="form.customerId"
            placeholder="Input Customer ID"
            :trigger-on-focus="false"
            ></el-autocomplete>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <h2 class="card-title">Order Information</h2>
    <div class="card info-card">
      <el-form ref="order-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row" v-if="checkIsAdmin()">
          <el-form-item label="Status">
            <el-select v-model="form.status" placeholder="Please select status">
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Remark" style="flex-basis: 59%;">
            <el-input
            type="textarea"
            :rows="3"
            v-model="form.remark" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <h2 class="card-title">Order Items</h2>
    <div class="card order-item-card">
      <div class="order-items-wrapper">
        <div class="action-button-wrapper">
          <div class="add-items-wrapper">
            <div class="order-form">
              <el-form ref="order-form" v-if="editMode" :disabled="!editMode" :model="form" label-width="200px">
                <el-form-item label="Order Item Name">
                  <el-input v-model="currentItemName"></el-input>
                </el-form-item>
                <el-form-item label="Order Item Amount">
                  <el-input v-model="currentItemAmount"></el-input>
                </el-form-item>
                <el-form-item label="Order Item Number">
                  <el-input v-model="currentItemNumber"></el-input>
                </el-form-item>
              </el-form>
            </div>
            <button v-if="editMode" class="btn btn-primary addButton" @click="saveItem">Add</button>
          </div>
          <button v-if="!editMode" class="btn btn-primary editButton" @click="startEditItem">Edit</button>
          <button v-if="editMode" class="btn btn-primary saveButton" @click="saveItem">Save</button>
        </div>

        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Order Item Name</th>
              <th scope="col">Order Item Amount</th>
              <th scope="col">Order Item Number</th>
              <th scope="col">Total Amount</th>
              <th v-if="editMode" scope="col">Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="orderItem in form.orderItems">
              <th scope="row">{{ orderItem.itemName }}</th>
              <td>{{ orderItem.itemAmount }}</td>
              <td>{{ orderItem.itemNumber }}</td>
              <td>{{ getItemTotalAmount(orderItem) }}</td>
              <td v-if="editMode"><a href="#" @click.prevent="removeItem(orderItem.itemName)">Remove</a></td>
            </tr>
          </tbody>
        </table>

        <div class="totalAmount">
          Total Amount:
          {{ getTotalAmount() }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Sales from '@/lib/sales';

export default {
  name: 'OrderForm',
  props:{
    viewMode: Boolean,
    eventName: String,
  },
  data(){
    let disabled = false;

    //Default form value
    let form = {
      customerId: '',
      status: '',
      remark: '',
      orderItems: [],
    };

    //If view mode is enabled, disable the form
    if(this.viewMode === true){
      disabled = true;
    };

    return {
      disabled,
      form,
      editMode: false,
      statusOptions:[
        {'label': 'Pending', 'value': 'Pending'},
        {'label': 'Processing', 'value': 'Processing'},
        {'label': 'Canceled', 'value': 'Canceled'},
        {'label': 'Processed', 'value': 'Processed'}
      ],
      currentItemName: '',
      currentItemAmount: 0,
      currentItemNumber: 0,
    };
  },
  methods:{
    checkIsAdmin(){
      const currentUser = this.currentUser;
      if(currentUser){
        const isAdmin = Common.checkIsAdmin(currentUser);
        return isAdmin;
      }
      return false;
    },
    startEditItem(){
      this.editMode = true;
    },
    async getCustomerByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'name': memberName,
      };
      const customers = await Sales.searchCustomer(this.apiUrl, searchFields, loginInfo);
      const customer = customers[0];
      return customer;
    },
    /* Customer Filter */
    createCustomerFilter(queryString) {
      return (customer) => {
        return (customer.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    /* For auto select */
    async searchCustomer(queryString, cb){
      const loginInfo = Common.getLoginInfo();
      const customers = await Sales.loadAllCustomers(this.apiUrl, loginInfo);
      const customerSuggest = [];

      for(let i = 0; i < customers.length; i++){
        const customer = customers[i];
        const customerSelection = {
          'value': customer.name,
          'name': customer.name
        };
        customerSuggest.push(customerSelection);
      }

      const results = queryString ? customerSuggest.filter(this.createCustomerFilter(queryString)) : customers;

      cb(results);
    },
    startEdit(){
      this.disabled = false;
    },
    getItemTotalAmount(item){
      const itemAmount = item.itemAmount;
      const itemNumber = item.itemNumber;
      const itemTotalAmount = itemAmount * itemNumber;
      return itemTotalAmount;
    },
    getTotalAmount(){
      let totalAmount = 0;
      const orderItems = this.form.orderItems;
      for(let i = 0; i < orderItems.length; i++){
        const orderItem = orderItems[i];
        const itemAmount = orderItem.itemAmount;
        const itemNumber = orderItem.itemNumber;
        const itemTotalAmount = itemAmount * itemNumber;
        totalAmount += itemTotalAmount;
      }
      return totalAmount;
    },
    removeItem(itemName){
      const newItems = this.form.orderItems.filter(function(item) {
        return item.itemName !== itemName;
      });
      this.form.orderItems = newItems;
      return false;
    },
    async saveItem(){
      const itemName = this.currentItemName;
      if(itemName){
        const item = {
          itemName,
          itemAmount: this.currentItemAmount,
          itemNumber: this.currentItemNumber
        };
        this.form.orderItems.push(item);
        this.currentItemName = '';
        this.currentItemAmount = 0;
        this.currentItemNumber = 0;
      }
      this.editMode = false;
    },
    async save(){
      this.$emit(this.eventName, this.form);
      this.disabled = true;
    },
  },
  watch: {
    'currentOrder': function(){
      this.form = {
        customerId: this.currentOrder.name,
        status: this.currentOrder.status,
        remark: this.currentOrder.remark,
        orderItems: this.currentOrder.order_items,
      };
    }
  },
  computed: mapState({
    currentUser: state => state.currentUser,
    currentOrder: state => state.sales.currentOrder,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.related-user-card{
  padding: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .related-user-form{
    margin-left: 15px;
    margin-top: 20px;
  }
}

.info-card{
  padding: 30px;
}

.card-title{
  margin-top: 30px;
  margin-bottom: 30px;
}

.order-item-card{
  margin-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.action-button-wrapper{
  display: flex;
  margin-bottom: 50px;
  align-items: flex-start;

  .editButton{
    margin-left: auto;
    position: relative;
    bottom: 10px;
    margin-right: 20px;
  }

  .saveButton{
    margin-left: auto;
    position: relative;
    bottom: 10px;
    margin-right: 20px;
  }

  .addButton{
    margin-left: auto;
    display: block;
  }
}

.totalAmount{
  margin-top: 20px;
  text-align: right;
  width: 100%;
}
</style>
