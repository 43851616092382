<template>
  <div class="content-wrapper">
    <div class="customer main-content">
      <div class="page-header">
        <h1>Customer Information</h1>
        <CustomerForm :viewMode="true" :eventName="'updateCustomer'" @updateCustomer="updateCustomer"/>

        <h2 class="card-title">Orders Information</h2>
        <div class="card info-card">
          <div class="orders-list">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Reference Id</th>
                  <th scope="col">Status</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Create Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="order in orders">
                  <td>{{order.reference_id}}</td>
                  <td>{{order.status}}</td>
                  <td>${{getTotalAmount(order)}}</td>
                  <td>{{ formatDateTime(order.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
                  <td class="action">
                    <router-link :to="{name:'Order', params: {id: order.id}}">View</router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CustomerForm from '@/components/sales/CustomerForm.vue';
import OrderForm from '@/components/sales/OrderForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Sales from '@/lib/sales';

export default {
  name: 'Customer',
  components: {
    CustomerForm,
  },
  data(){
    return {
      orders: [],
    }
  },
  mounted(){
    this.loadCustomer();
    this.loadOrders();
  },
  methods:{
    async loadCustomer(){
      const loginInfo = Common.getLoginInfo();
      try{
        const customerId = this.$route.params.id;
        const customer = await Sales.loadCustomer(this.apiUrl, customerId, loginInfo);
        this.setCurrentCustomer(customer);
      }catch(err){
        console.log(err);
      }
    },
    async loadOrders(){
      const loginInfo = Common.getLoginInfo();
      try{
        const customerId = this.$route.params.id;
        const searchFields = {
          'customer_id': customerId,
        };
        const orders = await Sales.searchOrder(this.apiUrl, searchFields, loginInfo);
        this.orders = orders;
      }catch(err){
        console.log(err);
      }
    },
    getTotalAmount(order){
      let totalAmount = 0;
      const orderItems = order.order_items;
      for(let i = 0; i < orderItems.length; i++){
        const orderItem = orderItems[i];
        const itemAmount = orderItem.item_amount;
        const itemNumber = orderItem.item_number;
        const itemTotalAmount = itemAmount * itemNumber;
        totalAmount += itemTotalAmount;
      }
      return totalAmount;
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async updateCustomer(form){
      try{
        const customerInfo = Object.assign({}, form);
        const loginInfo = Common.getLoginInfo();
        const customerId = this.$route.params.id;

        const employee = await this.getEmployeeByName(form.principalEmployeeId);
        const employeeId = employee.id;
        customerInfo.principalEmployeeId = employeeId;
        const customer = await Sales.updateCustomer(this.apiUrl, customerId, customerInfo, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentCustomer']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
.action{
  a{
    margin-right: 10px;
  }
}
</style>
